import { Controller } from '@hotwired/stimulus';

/**
 * A search select is a component in which one or more values can be searched and selected. The options of the search
 * select can be rendered locally or can be retrieved via an API.
 *
 * The following options can be dynamically changed in the search select:
 * - The `disabled` attribute on the select element. The controller detects using a MutationObserver when this values
 *   has changed. If disabled is set, it will rerender the search select in disabled state (user cannot use the search
 *   select to select anything)
 * - The `data-search-select-component-api-url-value` attribute on the (wrapper) element of this search select. The
 *   controller detects using a MutationObserver when this values has changed. When a value is set, it is considered
 *   to be an API search select and the options will be retrieved from the URL provided in that attribute and the
 *   search select is rerendered. Otherwise, the search select is considered to be a collection search select and it
 *   is the responsibility of the code that unsets the attribute to ensure the correct (collection) options are added
 *   to the select (after which a `refresh` event should be triggered, see below).
 * - A `refresh` event (`new Event('refresh')`) can be dispatched on the (wrapper) element of this search select to
 *   rerender the list items of the search select based on the options that are present within the select. This can be
 *   used after you changed the options of the select. Note no mutation observer is used for this because it is hard to
 *   detect all state changes (for example, it is possible to set the value of a select by doing `select.value = '1'`,
 *   but this does not set a value attribute on the select and hence will also not trigger the mutation observer).
 */
export default class SearchSelectComponentController extends Controller {
  static values = {
    multiple: { type: Boolean, default: true },
    apiUrl: { type: String },
    apiSearchParam: { type: String, default: 'q' },
    apiPaginationParam: { type: String, default: 'page' },
  };

  static targets = [
    'loading',
    'menu',
    'menuToggle',
    'placeholder',
    'selected',
    'selectedText',
    'selectedClearButton',
    'search',
    'select',
    'option',
    'listItem',
    'listItemText',
    'listItemIcon',
    'listItemTemplate',
    'noResultsListItem',
    'tag',
    'border',
  ];

  static enterKey = 'Enter';

  static arrowUpKey = 'ArrowUp';

  static arrowDownKey = 'ArrowDown';

  static escapeKey = 'Escape';

  static tabKey = 'Tab';

  /**
   * NoValues are used in filters to allow users to apply a filter with a no value condition. For example, select
   * bank transactions that have no cash expectations (Unexpected) or cash expectations without expected contra
   * bank accounts. The empty string option value is already used by the search select for not selecting any option in
   * the select. Hence, this dedicated value is used for selecting those no value options. The behaviour of options /
   * list items of the no value are different compared to other options / items. Because APIs don't return these no
   * values, the options / items are never removed because else they will disappear after an API request. In case of
   * an API search select, searching is performed by the API. Because the no value is not known by the API, users can
   * not search on the no value. Hence, the no value list item is hidden when the users performs an API search.
   * NOTE: It should be equal to FilterTypes::Abstract::NO_VALUE.
   */
  static noValue = 'NO_VALUE';

  connect() {
    this.boundClickOutside = this.clickOutside.bind(this);
    window.addEventListener('click', this.boundClickOutside);
    window.addEventListener('touchend', this.boundClickOutside);

    this.setInitialState();

    // Observer on the html select element
    this.selectObserver = new MutationObserver(this.selectObserverCallback.bind(this));
    this.selectObserver.observe(this.selectTarget, { attributes: true, attributeFilter: ['disabled'] });

    // Observer on the entire search select element
    this.searchSelectObserver = new MutationObserver(this.searchSelectObserverCallback.bind(this));
    this.searchSelectObserver.observe(
      this.element,
      { attributes: true, attributeFilter: ['data-search-select-component-api-url-value'] },
    );

    // NOTE: This event is not intended to be used with a TreeSelect since the UI of a list item of a TreeSelect
    // is considerably different and is not supported by this method.
    this.element.addEventListener('refresh', this.rerenderListItems.bind(this));
  }

  disconnect() {
    window.removeEventListener('click', this.boundClickOutside);
    window.removeEventListener('touchend', this.boundClickOutside);
    this.selectObserver.disconnect();
    this.searchSelectObserver.disconnect();
  }

  setInitialState() {
    if (this.selectTarget.disabled) {
      this.disable();
    } else {
      this.enable();
    }

    if (this.apiUrlValue) {
      this.currentPage = 1;
      this.menuTarget.addEventListener('scroll', this.handleMenuScroll.bind(this));
      this.loadSelectedValuesFromApi();
    } else {
      // NOTE: Remove all tags. This is needed on a page with turbo links: when
      // using the back button it will somehow remember the previously selected
      // tags resulting in all selected options being shown twice.
      this.tagTargets.forEach((tag) => tag.remove());
      this.optionTargets
        .filter((option) => option.selected)
        .forEach((option) => this.selectOption(option));
    }
  }

  search() {
    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }

    this.searchTimeoutId = setTimeout(async () => {
      if (this.apiUrlValue) {
        this.currentPage = 1;
        await this.apiSearch();
        return;
      }

      const listItems = this.listItemTargets;
      const regex = new RegExp(this.searchTarget.value, 'ig');
      const matches = listItems.filter((option) => option
        .querySelector('[data-search-select-component-target="listItemText"]').textContent.match(regex));

      listItems.forEach((item) => {
        SearchSelectComponentController.setSearchResult(item, regex, matches);
      });

      if (matches.length === 0) {
        this.noResultsListItemTarget.classList.remove('hidden');
      } else {
        this.noResultsListItemTarget.classList.add('hidden');
      }
    }, 500);
  }

  optionClicked(event) {
    const option = this.getOptionByValue(event.params.value);
    const isSelected = option.selected;

    if (isSelected) {
      this.unselectOption(option);
    } else {
      this.selectOption(option);
    }

    // Dispatch a change event on the select to make it compatible with onchange hooks for input fields.
    // This is used in the filter bar.
    this.selectTarget.dispatchEvent(new Event('change'));
    this.unfocusSearch();
  }

  componentKeydown(event) {
    // The escape button should close the menu whenever the focus is anywhere inside the component.
    // The same holds when tabbing to the next element.
    if (event.key === SearchSelectComponentController.escapeKey
      || event.key === SearchSelectComponentController.tabKey) {
      this.unfocusSearch();
    }
  }

  searchInputKeydown(event) {
    if (event.key === SearchSelectComponentController.arrowDownKey) {
      event.preventDefault(); // Prevent dropdown scroll-y
      // Focus on the first search result in the list that is visible and enabled when pressing the arrow-down button
      // from within the search input field.
      this.menuTarget.querySelectorAll(
        '[data-search-select-component-target="listItem"][aria-disabled="false"][aria-hidden="false"]',
      )?.[0]?.focus();
    } else {
      this.search();
    }
  }

  // The keydown event is used because navigating through the results should continue when keeping the arrow button
  // pressed.
  optionKeydown(event) {
    if (event.key === SearchSelectComponentController.enterKey) {
      this.optionClicked(event);
    } else if (event.key === SearchSelectComponentController.arrowUpKey) {
      event.preventDefault(); // Prevent dropdown scroll-y
      this.previousEnabledSibling(event.target)?.focus();
    } else if (event.key === SearchSelectComponentController.arrowDownKey) {
      event.preventDefault(); // Prevent dropdown scroll-y
      SearchSelectComponentController.nextEnabledSibling(event.target)?.focus();
    }
  }

  // Find the next item in the list of search results that is visible and enabled.
  static nextEnabledSibling(element) {
    let next = element;

    do {
      next = next.nextSibling;

      if (SearchSelectComponentController.isVisibleEnabledListItem(next)) {
        return next;
      }
    } while (next != null);

    return null;
  }

  previousEnabledSibling(element) {
    let previous = element;

    do {
      previous = previous.previousSibling;

      if (previous === this.noResultsListItemTarget) {
        // this.noResultsListItemTarget is the first item in the list. Whenever previous is equal to that element, we
        // put the focus on the search input.
        return this.searchTarget;
      }
      if (SearchSelectComponentController.isVisibleEnabledListItem(previous)) {
        return previous;
      }
    } while (previous != null);

    return null;
  }

  selectOption(option) {
    if (this.multipleValue) {
      this.addTag(option);
    } else {
      const selectedOption = this.optionTargets.find((o) => o.selected);
      this.unselectOption(selectedOption);

      if (option.value) {
        this.showSingleValue(option);
      }
    }

    // Select the new option
    option.selected = true;
    option.setAttribute('selected', 'true');

    const listItem = this.getListItemByValue(option.value);
    listItem?.setAttribute('aria-selected', true);
    listItem?.classList.add('font-bold');

    const icon = listItem?.querySelector('[data-search-select-component-target="listItemIcon"]');
    icon?.classList.remove('hidden');
  }

  unselectOption(option) {
    // Do not allow to unselect options when the `select` element has been
    // disabled.
    if (this.selectTarget.disabled) {
      return;
    }
    option.selected = false;
    option.removeAttribute('selected');

    const listItem = this.getListItemByValue(option.value);
    listItem?.setAttribute('aria-selected', false);
    listItem?.classList.remove('font-bold');

    const icon = listItem?.querySelector('[data-search-select-component-target="listItemIcon"]');
    icon?.classList.add('hidden');

    if (this.multipleValue) {
      this.tagTargets.find((tag) => tag.dataset.value === option.value).remove();

      if (this.tagTargets.length === 0) {
        this.showPlaceholder();
      }
    } else {
      this.showPlaceholder();
    }
  }

  showSingleValue(option) {
    this.selectedTextTarget.textContent = this.displayText(option);

    if (this.hasSelectedClearButtonTarget) { // Disabled search select has no clear button target
      this.selectedClearButtonTarget.setAttribute('data-search-select-component-value-param', option.value);
    }

    this.selectedTarget.classList.remove('hidden');
    this.placeholderTarget.classList.add('hidden');
  }

  showPlaceholder() {
    this.selectedTextTarget.textContent = '';

    if (this.hasSelectedClearButtonTarget) { // Disabled search select has no clear button target
      this.selectedClearButtonTarget.setAttribute('data-search-select-component-value-param', '');
    }

    this.selectedTarget.classList.add('hidden');
    this.placeholderTarget.classList.remove('hidden');
  }

  addTag(option) {
    this.selectedTarget.classList.remove('hidden');
    const tag = document.createElement('span');
    tag.classList.add(
      'bg-primary-500',
      'cursor-pointer',
      'group',
      'inline-block',
      'leading-none',
      'max-w-full',
      'mb-px',
      'mr-2',
      'overflow-hidden',
      'pb-1',
      'pl-2',
      'pt-1',
      'relative',
      'rounded',
      'select-none',
      'text-white',
      'whitespace-no-wrap',
    );
    tag.dataset.value = option.value;
    tag.dataset.searchSelectComponentTarget = 'tag';
    tag.tabIndex = 0;
    tag.setAttribute(
      'data-action',
      'click->search-select-component#tagClicked keydown->search-select-component#tagKeydown',
    );

    const content = document.createElement('span');
    content.textContent = this.displayText(option);

    tag.appendChild(content);
    const tagIcon = document.createElement('i');
    tagIcon.classList.add(
      'fas',
      'fa-xmark',
      'fa-sm',
      'font-bold',
      'inline',
      'leading-5',
      'ml-2.5',
      'mr-1.5',
      'not-italic',
      'rounded',
      'text-primary-600',
      'w-5',
      'focus:outline-0',
      'group-hover:text-white',
    );

    tag.appendChild(tagIcon);
    this.selectedTarget.appendChild(tag);
  }

  tagClicked(event) {
    event.stopPropagation();
    this.unfocusSearch();

    const option = this.getOptionByValue(
      event.target.closest('[data-search-select-component-target="tag"]').dataset.value,
    );
    this.unselectOption(option);

    // Dispatch a change event on the select to make it compatible with onchange hooks for input fields.
    // This is used in the filter bar.
    this.selectTarget.dispatchEvent(new Event('change'));
  }

  tagKeydown(event) {
    if (event.key === SearchSelectComponentController.enterKey) {
      this.tagClicked(event);
    }
  }

  focusSearch() {
    this.menuTarget.classList.remove('hidden');
    this.menuTarget.setAttribute('aria-expanded', true);
    this.menuToggleTarget.innerHTML = '&#8963;';
    // Apparently, the font size for the arrow up symbol should be different than the arrow down symbol to obtain
    // a similarly sized icon.
    this.menuToggleTarget.classList.add('mt-2', 'text-lg', 'font-bold');

    this.placeholderTarget.classList.add('hidden');
    this.searchTarget.classList.remove('hidden');
    this.searchTarget.focus();

    if (this.apiUrlValue) {
      this.currentPage = 1;
      this.apiSearch();
    }
  }

  unfocusSearch() {
    this.searchTarget.value = '';
    this.searchTarget.classList.add('hidden');
    this.menuTarget.classList.add('hidden');
    this.menuTarget.setAttribute('aria-expanded', false);
    this.menuToggleTarget.innerHTML = '&#8964;';
    this.menuToggleTarget.classList.remove('mt-2', 'text-lg', 'font-bold');

    if (!this.selectTarget.value) {
      this.placeholderTarget.classList.remove('hidden');
    }

    this.resetSearchResults();
  }

  static setSearchResult(listItem, regex, matches) {
    if (matches.includes(listItem)) {
      listItem.classList.remove('hidden');
      listItem.setAttribute('aria-hidden', false);
      const listItemText = listItem.querySelector('[data-search-select-component-target="listItemText"]');
      listItemText.innerHTML = `<span>${listItemText.textContent.replaceAll(regex, (str) => `<u>${str}</u>`)}</span>`;
      listItem.querySelectorAll('[data-search-select-component-target="listItemHide"]')
        .forEach((hideItem) => hideItem.classList.add('hidden'));
      listItem.querySelectorAll('[data-search-select-component-target="listItemShow"]')
        .forEach((showItem) => showItem.classList.remove('hidden'));
    } else {
      listItem.classList.add('hidden');
      listItem.setAttribute('aria-hidden', true);
    }
  }

  resetSearchResults() {
    this.listItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      item.setAttribute('aria-hidden', false);
      const listItemText = item.querySelector('[data-search-select-component-target="listItemText"]');
      listItemText.innerHTML = listItemText.textContent;
      item.querySelectorAll('[data-search-select-component-target="listItemHide"]')
        .forEach((hideItem) => hideItem.classList.remove('hidden'));
      item.querySelectorAll('[data-search-select-component-target="listItemShow"]')
        .forEach((showItem) => showItem.classList.add('hidden'));
    });
    this.noResultsListItemTarget.classList.add('hidden');
    this.noResultsListItemTarget.setAttribute('aria-hidden', true);
  }

  /**
   * Removes all list items and adds new list items based on the options of the select. Selected options are rendered
   * as selected list items.
   */
  rerenderListItems() {
    // Rerender entire item list when an option has been added or removed.
    this.removeAllListItems();

    const items = [];
    this.optionTargets.forEach((option) => {
      if (option.value === '') return; // The `no value` option is not rendered as list item
      items.push(this.listItem(option.text, option.value));

      if (this.selectTarget.value === option.value || option.selected) {
        this.selectOption(option);
      }
    });
    if (this.selectTarget.value === '') this.showPlaceholder();

    // For performance reasons the items are added at once instead of one by one. Note we append the items instead of
    // replacing the content since menuTarget contains besides the selectable list items also a `noResultsListItem`.
    this.menuTarget.innerHTML += items.join('');
  }

  toggle() {
    // Do not allow to open the search select when the `select` element has
    // been disabled.
    if (this.selectTarget.disabled) {
      return;
    }

    if (this.menuTarget.getAttribute('aria-expanded') === 'true') {
      this.unfocusSearch();
    } else {
      this.focusSearch();
    }
  }

  toggleKeydown(event) {
    if (event.key === SearchSelectComponentController.enterKey) {
      event.preventDefault(); // Prevent the enter button triggers a form submit.
      this.toggle();
    }
  }

  clickOutside(event) {
    if (this.element.contains(event.target)) {
      return;
    }

    this.unfocusSearch();
  }

  getOptionByValue(value) {
    return this.optionTargets.find((option) => option.value === value.toString());
  }

  getListItemByValue(value) {
    return this.listItemTargets.find((item) => item.dataset.searchSelectComponentValueParam === value);
  }

  // eslint-disable-next-line class-methods-use-this
  displayText(option) {
    if (option.dataset.searchSelectComponentDisplayTextParam != null) {
      return option.dataset.searchSelectComponentDisplayTextParam;
    }
    return option.text;
  }

  /**
   * Callback that is triggered when the attributes of the select element are
   * changed
   */
  selectObserverCallback(mutationList) {
    if (mutationList.filter((m) => m.type === 'attributes' && m.attributeName === 'disabled').length > 0) {
      if (this.selectTarget.disabled) {
        this.disable();
      } else {
        this.enable();
      }
    }
  }

  /**
   * Callback that is triggered when the attributes of the search select element are changed.
   */
  searchSelectObserverCallback(mutationList) {
    if (mutationList.filter((m) => m.type === 'attributes'
      && m.attributeName === 'data-search-select-component-api-url-value').length > 0) {
      if (this.apiUrlValue != null) {
        this.removeAllSelectOptions();
        this.rerenderListItems();

        this.currentPage = 1;
        this.menuTarget.addEventListener('scroll', this.handleMenuScroll.bind(this));
      } else {
        // The search select is a collection search select. Note that the API options/list items are not removed
        // here from the select. This is because the code that unsets the api url anyway has to indicate what the new
        // options of the collection search select are and has to add them. So it is the responsibility of that code
        // to replace those options and trigger a `refresh` event to rerender the list items.
        this.menuTarget.removeEventListener('scroll');
      }
    }
  }

  enable() {
    this.menuToggleTarget.setAttribute('tabindex', '0');
    this.borderTarget.classList.remove('opacity-70');

    // Enable the blue "tags" in case of a multi select
    this.tagTargets.forEach((tag) => {
      tag.classList.add('bg-primary-500');
      tag.classList.remove('bg-slate-400');
      tag.tabIndex = '0';
      tag.getElementsByClassName('fa-xmark')[0]?.classList.remove('invisible');
    });
  }

  disable() {
    this.menuToggleTarget.setAttribute('tabindex', '-1');
    this.borderTarget.classList.add('opacity-70');

    // Disable the blue "tags" in case of a multi select
    this.tagTargets.forEach((tag) => {
      tag.classList.remove('bg-primary-500');
      tag.classList.add('bg-slate-400');
      tag.tabIndex = '-1';
      tag.getElementsByClassName('fa-xmark')[0]?.classList.add('invisible');
    });
  }

  static isVisibleEnabledListItem(element) {
    return element?.nodeName === 'LI'
      && element?.getAttribute('aria-disabled') === 'false'
      && element?.getAttribute('aria-hidden') === 'false';
  }

  /**
   * Search for results at an API endpoint and add those results to the result list. A loading indicator is shown while
   * retrieving the results.
   */
  async apiSearch() {
    const page = this.currentPage;
    this.menuToggleTarget.classList.replace('opacity-100', 'opacity-0');
    this.loadingTarget.classList.replace('opacity-0', 'opacity-100');

    try {
      await fetch(this.searchUrl(this.searchTarget.value, page), { redirect: 'manual' })
        .then((response) => SearchSelectComponentController.getDataFromApiResponse(response))
        .then((data) => {
          // Since searching is done in the backend (API) we don't know whether the label of the NO_VALUE matches the
          // search query. Hence, we hide the NO_VALUE when the user is performing a search.
          this.noValueListItem()?.classList?.toggle('hidden', this.searchTarget.value !== '');

          if (page === 1) {
            // Scroll to the top of the menu. Without this the scroll apparently remains at the last position.
            this.menuTarget.scrollTo(0, 0);

            // Remove all previous results
            this.removeAllListItems(false);
            // We do not remove selected options because these are used to determine which items returned by the API
            // should be rendered as selected list items or not.
            this.removeAllSelectOptions(false);
          }
          this.handleApiSearchResponse(data);
          this.adjustMenuMaxHeight();
        });
    } finally {
      // Use timeout to make the opacity transition more smooth
      setTimeout(() => {
        this.loadingTarget.classList.replace('opacity-100', 'opacity-0');
        this.menuToggleTarget.classList.replace('opacity-0', 'opacity-100');
      }, 300);
    }
  }

  /**
   * API search is triggered by scrolling down. Without vertical scrollbar in the menu this is not possible.
   * This method adjusts the max height of the result menu based on the number of items in the list to ensure the
   * vertical scrollbar is visible.
   */
  adjustMenuMaxHeight() {
    /*
         * Each result item (list item) in the menu takes 40px of height. The menu itself has a total border of 2px.
         * By default, the menu shows no scrollbar until 5 result items, because of the default max height of
         * 202px (5 * 40px + 2px). If the per_page of the API is <= than that, no scrollbar would be shown. Therefore we
         * adjust the maximum height to 1 pixel less than the height that is required. The amount of results per page
         * is determined by the amount of results that the first API search (page is 1 and the search term is empty)
         * returns. We multiply that by 40 px per result, add 2px for the menu border and subtract 1 px to ensure the
         * vertical scrollbar is shown.
         */
    if (this.listItemTargets.length > 0 && this.listItemTargets.length <= 5) {
      this.menuTarget.style['max-height'] = `${40 * this.listItemTargets.length + 1}px`;
    } else {
      this.menuTarget.style.removeProperty('max-height');
    }
  }

  /**
   * The URL to fetch the search results
   * @param searchValue A string that contains the value to search for.
   * @param page An integer with the page number for the search.
   * @param byId If the search url should search by id or by the api search param value. Defaults to false.
   * @returns {URL} The URL to search for.
   */
  searchUrl(searchValue, page, byId = false) {
    let url;

    if (this.apiUrlValue.startsWith('/')) {
      // The URL is a relative path. Add the current origin as URL base
      url = new URL(this.apiUrlValue, window.location.origin);
    } else {
      url = new URL(this.apiUrlValue);
    }

    if (byId) {
      if (Array.isArray(searchValue)) {
        searchValue.forEach((v) => url.searchParams.append('id[]', v));
      } else {
        url.searchParams.set('id', searchValue);
      }
    } else {
      url.searchParams.set(this.apiSearchParamValue, searchValue);
      url.searchParams.set(this.apiPaginationParamValue, page);
    }

    return url;
  }

  /**
   * Process the results from the API endpoint. For each result a list item and select option is added. When there
   * are no results, the no results placeholder is shown.
   * @param data An array of search results. A search result is an object that consists of an attribute text and value.
   */
  handleApiSearchResponse(data) {
    if (data.length !== 0) {
      // We found results, so we increase the page number to continue searching on the next page next request
      this.currentPage += 1;
    }

    const options = [];
    const items = [];
    data.forEach((element) => {
      const option = new Option(element.text, element.value);
      option.setAttribute('data-search-select-component-target', 'option');
      options.push(option.outerHTML);
      items.push(this.listItem(element.text, element.value));
    });
    this.selectTarget.innerHTML += options.join('');
    this.menuTarget.innerHTML += items.join('');

    this.noResultsListItemTarget.classList.toggle('hidden', this.listItemTargets.length !== 0);
  }

  /**
   * An API search select with selected values renders the select options without text because these are not known
   * by the server (only the selected values are stored). This method retrieves the text content of these selected
   * options/values, rerenders the list items with text and shows them as selected choices.
   */
  loadSelectedValuesFromApi() {
    // Selected options for which the text value needs to be retrieved from the API.
    const values = this.optionTargets
      .filter((option) => option.selected && option.value !== '' && option.textContent === '')
      .map((option) => option.value);

    if (values.length === 0) {
      // No options are selected or the text value of the selected options is already known. The list items are
      // rerendered to ensure that the search select is shown in a correct state. For example, that the selected options
      // for which the text values are already known are shown as selected in the search select (RM-2840).
      this.rerenderListItems();
      return;
    }

    // NOTE: In theory more results can be selected than fit on a single API page. This method does not take
    // this into account!
    fetch(this.searchUrl(values, 0, true), { redirect: 'manual' })
      .then((response) => SearchSelectComponentController.getDataFromApiResponse(response))
      .then((data) => {
        const foundValues = [];

        data.forEach((item) => {
          // Note: the option `values` are strings while the API returns numbers, hence we call toString() such that
          // `values` and `foundValues` both contain strings and can be compared.
          foundValues.push(item.value.toString());
          const option = this.getOptionByValue(item.value);
          option.textContent = item.text;
        });

        // The options of values that are not found in the API are removed such that they will not be rendered (because
        // we don't have a text to render it with).
        values.filter((v) => !foundValues.includes(v)).forEach((v) => {
          this.getOptionByValue(v).remove();
        });

        this.rerenderListItems();
      });
  }

  // Returns the list item that belongs to the select option that has the SearchSelectComponentController.noValue value.
  // This list item behaves special because it is rendered on page load  and is not returned by the API and hence
  // should not be removed.
  noValueListItem() {
    return this.listItemTargets
      .find((item) => item.dataset.searchSelectComponentValueParam === SearchSelectComponentController.noValue);
  }

  /**
   * Returns a promise of the json response if the status code of the response is ok. Otherwise a promise with an
   * empty array is returned.
   */
  static getDataFromApiResponse(response) {
    if (response.ok) {
      return response.json();
    }

    // The internal APIs of this application (like `/parties.json`) responds 302 when a user is not authorized
    // and renders a HTML page with a flash indicating the user is not authorized. Because this response is no
    // valid JSON, the search select is rendered without any options and hence we return an empty array.

    return Promise.resolve([]);
  }

  /**
   * Create a new list item for in the search result menu. This list item is created from a <template> in the HTML of
   * the search select.
   * @param text The visual text shown in the list item.
   * @param value The input value of the list item.
   */
  listItem(text, value) {
    const listItemTemplate = this.listItemTemplateTarget.content.firstElementChild.cloneNode(true);
    listItemTemplate.setAttribute('data-search-select-component-value-param', value);
    listItemTemplate.querySelector('[data-search-select-component-target="listItemText"]').textContent = text;

    // eslint-disable-next-line
    if (this.optionIsSelected(value)) {
      listItemTemplate.classList.add('font-bold');
      listItemTemplate.setAttribute('aria-selected', 'true');
      listItemTemplate.querySelector('[data-search-select-component-target="listItemIcon"]').classList.remove('hidden');
    }

    return listItemTemplate.outerHTML;
  }

  /**
   * Clears all list items from the search result menu. This is used to clear the results whenever a new search is
   * performed and before the new results are added.
   * @param removeNoValue Whether the list item that belongs to the SearchSelectComponentController.noValue value should
   *   be removed. With a collection search select this method is called with the value true, because all list items
   *   are rerendered afterwards anyway based on the select options. With an API search select not all list items are
   *   rerendered but only list items for the values retrieved by the paginated API request (which excludes the no
   *   value) because the entire list becomes increasingly large, hence the no value list item is not removed in that
   *   case.
   */
  removeAllListItems(removeNoValue = true) {
    this.listItemTargets.forEach((item) => {
      if (removeNoValue || item.dataset.searchSelectComponentValueParam !== SearchSelectComponentController.noValue) {
        item.remove();
      }
    });
  }

  /**
   * @param the value of an option tag
   * @return boolean whether this option is currently selected
   */
  optionIsSelected(value) {
    // selectedOptions returns a HTMLCollection which is converted to an Array
    // using ... such that map can be used.
    return [...this.selectTarget.selectedOptions].map((o) => o.value).includes(value.toString());
  }

  /**
   * Clears all options from the select. This is used to clear the options whenever a new search is
   * performed and before the new options are added.
   * @param removeSelected Whether options that are currently selected should be removed as well. Default is true.
   *   This option is set to false when doing an api search for the first page, because the selected options are used
   *   to determine which items returned by the API should be rendered as selected list items or not (see `apiSearch`
   *   method).
   */
  removeAllSelectOptions(removeSelected = true) {
    this.optionTargets.forEach((item) => {
      // Do not remove the current selected option(s) or the blank option (that allows users to unselect a value)
      // Also do not remove options without text because these are used as selected values for API (the text value
      // is retrieved via the API). In addition, the no value is not removed because it is not returned by the API and
      // otherwise would disappear after an API request has been done.
      if ((removeSelected || !this.optionIsSelected(item.value))
        && (removeSelected || item.text !== '')
        && !(item.value === '' && item.text === '')
        && item.value !== SearchSelectComponentController.noValue) {
        item.remove();
      }
    });
  }

  /**
   * Whenever the clear button is selected (e.g. by using tabs) and the user presses enter the option is being
   * deselected.
   */
  selectedClearButtonKeydown(event) {
    if (event.key === SearchSelectComponentController.enterKey) {
      // Deselecting the option is done by triggering an optionClicked. That method will unselect the option when it is
      // currently selected.
      this.optionClicked(event);
    }
  }

  /**
   * This method is triggered whenever the user scrolls down in the search result menu. It is used to trigger a new
   * api search to retrieve additional results.
   */
  handleMenuScroll(e) {
    const { scrollTop, scrollHeight, offsetHeight } = e.target;
    const contentHeight = scrollHeight - offsetHeight;
    if (contentHeight <= scrollTop) {
      this.apiSearch();
    }
  }
}
